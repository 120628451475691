/* eslint-disable */

body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: Nunito, BlinkMacSystemFont, sans-serif;
    color: #2f2f2f;
}

html {
    scroll-behavior: smooth;
}

h1 {
    font-size: 60px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 2px;
}

h4 {
    font-size: 25px;
    font-weight: 700;
    margin: 0;
}

@media only screen and (max-width: 700px), screen and (max-height: 600px) {
    h1 {
        font-size: 25px;
        letter-spacing: 1px;
    }

    h4 {
        font-size: 10px;
    }
}

#app {
    height: 100%;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #343434;
}
